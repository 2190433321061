import { cacheName } from './cache'
import { config } from './config'


declare var self: ServiceWorkerGlobalScope;
export { };


interface MessageEvent extends ExtendableEvent {
  data: string
}



export const delete_old_caches = async (sessionid: string) => {
  return self.caches.keys().then(
    (cacheNames) => cacheNames.filter((cacheName) => {
      if (cacheName === `${config.version}Static`) {
        return false;
      }
      if (cacheName === `${config.version}Image`) {
        return false;
      }
      return cacheName !== `${config.version}${sessionid}::Content`;
    })
      .map((cacheName) => self.caches.delete(cacheName)))
    .then(() => { config.sessionid = sessionid })

}



export const message_listener = (event: MessageEvent) => {
  let message;
  try {
    message = JSON.parse(event.data);
  } catch (err) {
    // console.warn("Error decoding json in service worker. This should not be a real problem.", err)
    return;
  }
  switch (message.header) {
    case "sessionid":
      const { sessionid } = message.body;
      event.waitUntil(delete_old_caches(sessionid));
      break;
    default:
      console.error("Service worker received message with unknown header", message.header);
  }
}
