import { config, Config } from './config';
declare var self: ServiceWorkerGlobalScope;
export { };


interface ExtendableEvent extends Event {
  waitUntil(fn: Promise<any>): void;
}

interface ActivateEvent extends ExtendableEvent {

}

const clearCacheIfDifferent = (event: ActivateEvent, opts: Config) => {
  return caches.keys().then(cacheKeys => {
    const oldCacheKeys = cacheKeys.filter(key => key.indexOf(opts.version) !== 0);
    const deletePromises = oldCacheKeys.map(oldKey => caches.delete(oldKey));
    return Promise.all(deletePromises);
  });
}

export const activate_listener = (event: ActivateEvent) => {
  event.waitUntil(clearCacheIfDifferent(event, config).then(() => self.clients.claim()))
}
